<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :name="field"
    :vid="vid"
    :rules="rules">
    <!--Field-->
    <div class="select__field"
         :class="{'errors__field': errors[0]}">
      <!--Select-->
      <select :value="value || value === '0' ? value : undefined"
              :disabled="disabled"
              @change="handleChange">
        <option value="" disabled selected>選択してください。</option>
        <option v-for="(option, index) in options"
                :key="index"
                :value="option[valueOption]">
          {{ option[nameOption] }}
        </option>
      </select>
    </div>

    <!--Message Error-->
    <span
      v-if="errors[0]"
      class="errors">
      {{ errors[0] }}
    </span>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'InputSelect',

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    field: {
      type: String,
      default: ''
    },

    rules: {
      type: String,
      default: ''
    },

    vid: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    nameOption: {
      type: [String, Number],
      default: 'text'
    },
    valueOption: {
      type: [String, Number],
      default: 'value'
    },

    options: {
      type: [Array, Object],
      default: () => []
    }
  },

  methods: {
    handleChange ($event) {
      this.$emit('change', $event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .select__field {
    width: 100%;
    select {
      width: 100%;
      height: 42px;
      padding: 7px;
      border-radius: 6px;
      background: #FFFFFF;
      color: #282828;
      &:disabled {
        background: rgba(255, 255, 255, 0.35);
        opacity: 1;
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
        &::-ms-expand {
          display: none;
        }
      }
    }
  }
</style>
